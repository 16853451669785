.slideshow-wrap {
    position: relative;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-size: 1.5vw;
        text-align: center;
        padding-bottom:0.5em;
    }
    .title {
        @extend .font-2;
        text-transform: uppercase;
        color: $color-primary;
        font-size: 3vw;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }
    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: 11px;
            border-width: 1px;
            padding: 5px;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .title {
            font-size: 26px;
        }
        .subtitle {
            font-size: 16px;
        }

    }
}

div.caption.container {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 37vw;
    @media screen and (max-width: 480px) {
        height: 125vw;
    }
    div.row {
        div.col-xs-12 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3 {
                color: #FFF;
                text-align: center;
                text-transform: none;
                font-size: 72px;
                font-weight: 900;
                @media screen and (max-width: 1625px) {
                    font-size: 3.8vw;
                    @media screen and (max-width: 767px) {
                        font-size: 4.8vw;
                        @media screen and (max-width: 767px) {
                            font-size: 30px;
                        }
                    }
                }
            }
            a {
                background: #ff1e00;
                color: #000d18;
                font-size: 22px;
                padding: 17px 25px;
                font-weight: 700;
                min-width: 265px;
                text-align: center;
                margin-top: 25px;
                border-radius: 2px;

                -webkit-box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.75);
                box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.75);
                transition: .5s;
            }
            a:hover {
                background: #961100;
                transition: .5s;
            }
        }
    }
}