.module-mosaique {
  .item {
    display: block;
		float: left;
		position: relative;
		width: 100%;
		&.top-blue {border-top: 7px solid #005981;}
		&.top-orange {border-top: 7px solid #ff3c22;}
		h3 {
			bottom: 0;
			color: #fff;
			display: block;
			font-size: 18px;
			left: 0;
			line-height: 1;
			margin: 0;
			right: 0;
			padding-bottom: 50px;
			position: absolute;
			text-align: center;
			@include transition(all 0.25s ease-out);
    }

    .item-image {
      display: block;
      position: relative;
      img {
        width: 100%;
      }
      .item-hover {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        display: block;
        position: absolute;
        background-color: rgba(197, 198, 200, 0.8);
        //background-color: rgba(255,60,34,0.8); orange
        @include transition(all 0.25s ease-out);
      }
    }
    &:hover {
      h3 {
        top: 50%;
        bottom: unset;
        display: block;
        @include transform(translateY(-50%));
      }
      .item-image {
        .item-hover {
          opacity: 1;
        }
      }
    }
  }
}
