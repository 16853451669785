.btn-soum {
  border: 2px solid #005981;
  display: block;
  font-size: 20px;
  font-weight: 900;
  margin: 25px 0;
  padding: 20px 0;
  text-align: center;
  width: 260px;
  @include transition(all 0.25s ease);
  background: #005981;
  color: #ffffff;
  &:hover {
    color: #005981;
    background: transparent;
  }
  @media screen and (max-width: 991px) {
    margin: 25px auto;
  }
}
