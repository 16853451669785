.module-header {
	position: relative;
	h3 {
		position: absolute;
		@include text-style(4vw,#fff,$font-family-1,300);
		top: 50%;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		transform: translateY(-50%);
		span {
			font-size: 3vw;
			font-weight: 400;
		}
	}
	@media screen and (max-width: 767px){
		h3 {
			font-size: 25px;
			span {
				font-size: 20px;
			}
		}
	}
}
