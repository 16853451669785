// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';

/* purgecss start ignore */

// @import 'addons/font-awesome/font-awesome';
@import 'addons/layerslider/layerslider';
//@import 'addons/magnific-popup/magnific-popup';
// @import 'addons/cube-portfolio/cubeportfolio';
// @import 'addons/parallax-scroll/normalize';
// @import 'addons/parallax-scroll/main';
@import 'addons/owl-carousel2/owl.carousel';

/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'reset';
@import 'variables';
@import 'functions';

/* GENERAL TEMPLATE */

body {
    background-color: $bg-color-body;
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
  content:"";
  display:block;
  height:$size-nav-height; /* fixed header height*/
  margin:-$size-nav-height 0 0; /* negative fixed header height */
}
.no-padding {
  padding: 0!important;
}
.gras {font-weight: 700;}
/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'menu';
// @import 'menu-centre';

@import 'btn';
/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-mosaique';
@import 'modules/soumission';




/*******************************************************************************
* COMMON TO ALL PAGES
*/
@import 'modules/module-header';

/*****************************************************/
/* INDEX.PHP
/*****************************************************/
a.btn-cliquez {
  color: #FFF;
  background: #005a80;
  margin-top: 35px;
  padding: 10px 55px;
  min-width: 259px;
  font-size: 20px;
  transition: .5s;
}

a.btn-cliquez:hover {
  background: #00374e;
  transition: .5s;
}

p {
  color: #464646;
}

div.text-box {
  @media screen and (max-width: 991px) {
    padding: 10px;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    p {
      @media screen and (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
}

.index {
  background-attachment: fixed;
  background-color: #e6e6e6;
  background-image: url('../images/Section01-bg.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
  padding: 75px 0 170px;
  .row {
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-start;
    @extend .justify-center;
    .left {
      padding-top: 10vw;
      img{
        margin: 20px auto;
      }
    }
    div.left.emballage-special,
    div.transport-left,
    div.about-left {
      padding-top: 5vw;
    }
    div.ecommer-page {
      padding-top: 5vw;
    }
    div.equipe-experiemente {
      padding-top: 0;
    }
    div.content-right-sides {
      display: flex;
      justify-content: center;
      align-items: center;
      div.text-box {
        display: flex;
        justify-content: center;
        //align-items: center;
        flex-direction: column;
        @media screen and (max-width: 991px) {
          align-items: center;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    background-image: none;
		padding: 50px 10px;
  }
  div.row.emballage-special {
    display: flex;
    align-items: center;
    justify-content: center;
    div.left.emballage-special {}
    div.text-box {
      @media screen and (max-width: 991px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
      }
    }
    p.first-paragrphe {
      width: 90%;
      font-size: 18px;
      @media screen and (max-width: 991px) {
          width: 100%;
          text-align: center;
      }
    }
    ul.list-sections {
      width: 85%;
      @media screen and (max-width: 991px) {
          width: 90%;
          text-align: center;
      }
      li {
        color: #005a80;
        padding-bottom: 25px;
      }
    }
    h4.question {
      text-transform: none;
      color: #252525;
      font-weight: 600;
      font-size: 22px;
      padding-bottom: 30px;
    }
    a.btn-cliquez {
      padding: 19px 64px;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}

div.row.ecpmmerce-page-row,
div.row.transport-row,
div.row.services-page {
  div.text-box {
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }
    p {
      color: #464646;
      @media screen and (max-width: 991px) {
        width: 90%;
        text-align: center;
        @media screen and (max-width: 767px) {
          font-size: 18px;
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}

article.index.emplois {
  div.container-fluid {
    div.row {
      div.text-box {
        @media screen and (max-width: 991px) {
          text-align: center;
        }
      }
    }
  }
}

section.postes.emplois {
  div.container {
    div.row {
      div.col-xs-12.col-sm-6 {
        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }
}
span.bold-upercase {
  color: #000;
  font-weight: 700;
}

.map-two-address {
  padding-top: 55px;
}

.historique {
  .index {background-image: url('../images/body_bg.jpg');}
}
.section02 {
  padding: 50px 0;
  text-align: center;
  img {margin: 30px auto;}
  h3 {
    color: $color-blue-text;
    font-weight: 700;
    font-size: 30px;
  }
}
.prix {
  padding: 100px 0;
  background: $color-grey;
}
.contact {
  .text-box {
    text-align: right;
    padding: 50px;
    h3 {color: $color-blue-text;}
    p {padding: 0;}
    h4{color: $color-black;}
    a {
      color: $color-blue-text;
      &:hover {
        color: $color-black;
      }
    }
  }
  background: $color-grey;
  @media screen and (max-width: 767px){
    padding: 50px 0;
    .text-box {
      text-align: center;
      padding: 0 10px;
    }
  }
}

div.text-box.contact-page {
  h3.gras {
    font-size: 40px;
    margin-bottom: 25px;
    @media screen and (max-width: 991px) {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 991px) {
    text-align: center;
  }
}

h3.title-up-section {
  color: $color-blue-text;
  padding-top: 30px;
  font-weight: 600;
  font-size: 38px;
  line-height: 42px;
  @media screen and (max-width: 1650px) {
    font-size: 30px;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
      padding: 15px;
      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }
  }
}

.entreposage {
  .index {
    padding: 70px 0;
    background-size: auto;
    background-image: url('../images/body_bg.jpg');
    ul {
      margin-top: 60px;
      li {
        font-weight: 600;
        font-size: 22px;
        padding-bottom: 30px;
        color: #005a80;
        line-height: 1.4;
        @media screen and (max-width: 991px) {
          text-align: center;
        }
      }
    }
    div.separator-line {
      width: 80%;
      margin: 40px auto 0;
      border-top: 2px solid #005a80;
    }
    div.last-paragraphe {
      width: 80%;
      text-align: center;
      margin: auto;
      padding-top: 30px;
      @media screen and (max-width:480px) {
        width: 100%;
      }
      p {
        font-size: 18px;
        line-height: 30px;
        span.bold-upercase {
          font-weight: 700;
          color: #000;
        }
      }
      span {
        font-size: 24px;
        color: #005a80;
        font-weight: 700;
        letter-spacing: -.5px;
      }
      a.btn-cliquez {
        color: #FFF;
        background: #005a80;
        margin-top: 35px;
        padding: 10px 55px;
        min-width: 259px;
        font-size: 20px;
        transition: .5s;
      }
      a.btn-cliquez:hover {
        background: #00374e;
        transition: .5s;
      }
    }

    @media screen and (max-width: 767px){
      padding: 50px 0;
      .text-box {
        text-align: center;
        padding: 0 10px;
      }
    }
  }
}

.distribution {
  .index {
    background-size: auto;
    background-image: url('../images/body_bg.jpg');
    padding: 70px 0 170px;
    div.container-fluid {
      div.row {
        p {
          color: #404040;
          font-weight: 400;
          font-size: 18px;
          padding: 15px;
          @media screen and (max-width: 1950px) {
            width: 75%;
            @media screen and (max-width: 1155px) {
              width: 80%;
              @media screen and (max-width: 991px) {
                width: 90%;
                @media screen and (max-width: 767px) {
                  width: 100%;
                  @media screen and (max-width: 480px) {
                    text-align: center;
                  }
                }
              }
            }
          }

          h3.title-up-section {
            @media screen and (max-width: 767px) {
              font-size: 22px;
              padding: 15px;
            }
          }
        }
        div.container-fluid.row {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 75px;
          @media screen and (max-width: 991px) {
            margin-top: 0;
          }
          div.content-elements {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 45px;
            width: 50%;
            min-width: 1040px;
            @media screen and (max-width: 1155px) {
              min-width: 80%;
              @media screen and (max-width: 991px) {
                width: 90%;
                @media screen and (max-width: 480px) {
                  flex-direction: column;
                  width: 100%;
                }
              }
            }
            div.content-img {
              width: 15%;
              min-width: 110px;
              margin-right: 35px;
              @media screen and (max-width: 767px) {
                margin-right: 0;
                @media screen and (max-width: 490px) {
                  min-width: 90px;
                }
              }
            }
            div.content-text-paragraph {
              width: 85%;
              @media screen and (max-width: 480px) {
                width: 100%;
              }
              p {
                color: #005a80;
                font-weight: 600;
                font-size: 25px;
                @media screen and (max-width: 767px) {
                  font-size: 18px;
                  @media screen and (max-width: 480px) {
                    text-align: center;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    p {
      color: $color-blue-text;
      font-weight: 700;
    }
    ul {
      li {
        text-transform: uppercase;
        font-weight: 400;
      }
    }
    @media screen and (max-width: 767px){
      padding: 50px 0;
      .text-box {
        padding: 0 10px;
      }
    }
  }
}

article.index.courtage-page {
  padding-bottom: 0;
  div.container-fluid {
    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }
  p {
    @media screen and (max-width: 555px) {
      font-size: 18px;
    }
  }
}

section.content-elements-after {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 125px 15px;
  padding-left: 0px;
  padding-bottom: 0;
  @media screen and (max-width: 991px) {
    padding-left: 15px;
  }

  div.container-fluid {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0;
    margin-bottom: 65px;
    @media screen and (max-width: 991px) {
      padding-left: 15px;
      @media screen and (max-width: 555px) {
        flex-direction: column;
      }
    }
    div.left-side-content-img {
      width: 37%;
      @media screen and (max-width: 555px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
      }
    }
    div.right-side-content-text {
      width: 63%;
      padding-left: 5vw;
      @media screen and (max-width: 555px) {
        width: 100%;
        padding-left: 0;
      }
      h3.top-ttile-1 {
        color: #005a80;
        font-size: 35px;
        font-weight: 600;
        @media screen and (max-width: 1100px) {
          font-size: 30px;
          @media screen and (max-width: 991px) {
            font-size: 25px;
          }
        }
      }
      p.paragraph {
        font-size: 30px;
        font-weight: 400;
        color: #3c3c3c;
        line-height: 45px;
        width: 88%;
        @media screen and (max-width: 1100px) {
          font-size: 24px;
          line-height: 28px;
          width: 92%;
          @media screen and (max-width: 991px) {
            width: 100%;
            font-size: 20px;
            line-height: 20px;
            @media screen and (max-width: 555px) {
              font-size: 18px;
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
  div.container-fluid:nth-last-child(1) {
    margin-bottom: 0;
  }
}

div.row.nos-installations-page {
  section.content-elements-after {
    @media screen and (max-width: 480px) {
      padding: 0;
    }
    div.right-side-content-text {
      width: 40%;
      @media screen and (max-width: 1554px) {
        width: 64%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    h3 {
      color: #005a80;
      font-size: 35px!important;
      font-weight: 600;
      @media screen and (max-width: 1100px) {
        font-size: 30px;
        @media screen and (max-width: 991px) {
          font-size: 25px;
        }
      }
    }

    p {
      font-size: 25px!important;
      font-weight: 400;
      color: #3c3c3c;
      line-height: 45px;
      width: 88%;
      span.blue-uperpace {
        font-weight: 700;
        text-transform: uppercase;
        color: #005a80;
      }
      @media screen and (max-width: 1100px) {
        font-size: 22px!important;
        line-height: 28px;
        width: 92%;
        @media screen and (max-width: 991px) {
          width: 100%;
          font-size: 18px!important;
          line-height: 20px;
          @media screen and (max-width: 555px) {
            font-size: 16px!important;
            padding-top: 5px;
          }
        }
      }
    }

  }
}
ul {
  list-style: none;
  list-style-type: none;
  li{
    color: #005a80;
    font-weight: 600;
  }
}

.transport {
  .index {
    h4 {color: $color-black;}
  }
}

/*******************************************************************************
* X PAGE
*/

.pageX {
    section.header {
        transition: all .2s linear;
        &:hover {transition: all .2s linear;}
    }
}


.section-temoignages {
	text-align: center;
  padding: 100px 0;
	.head {
		display: block;
		position: relative;
		h3 {
			color: $color-blue-text;
			font-size: 36px;
			font-weight: bold;
			line-height: 120%;
			margin-bottom: 30px;
		}
		.arrow {
			top: 2px;
			width: 20px;
			height: 36px;
			display: table;
			cursor: pointer;
			position: absolute;
			@include translate(-50%, 0);
      background-image: url('../images/nav-ss-tempignage.png');
			&.previous {
				left: 50%;
				margin-left: 200px;
				background-position: -40px 0;
			}
			&.next {
				left: 50%;
				margin-left: -200px;
				background-position: 0 0;
			}
		}
	}
	.desc {
		clear: both;
		color: $color-black;
		font-size: 30px;
		font-weight: 300;
		line-height: 120%;
		margin-bottom: 30px;
	}
	.info {
		clear: both;
		color: #282828;
		font-size: 24px;
		font-weight: 300;
		line-height: 120%;
	}
	@media screen and (max-width: 767px) {
		.head {
			h3 {
				font-size: 28px;
			}
			.arrow {
				margin-top: -2px;
				&.previous {
					margin-left: 130px;
				}
				&.next {
					margin-left: -130px;
				}
			}
		}
		.desc {
			font-size: 24px;
		}
		.info {
			font-size: 20px;
		}
	}
}
.emplois {
   .postes {
     background: #fff;
     padding: 100px 0;
     .text-box {border-bottom: 1px solid $color-blue;}
   }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-sm-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-xs-max){
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {
        h2 {
            font-size: 3em;
        }
    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
