html {
    // To add lining figures
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    -ms-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
}

body {
    // to prevent iphone and ipad side sliding
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin:0;

    h1.referencement {
        display: none !important;
    }
    .appear {
        opacity: 0;
        @include transition(opacity 0.75s ease-in);
    }
    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: 768px) {
            background-attachment: scroll;
        }
    }
}


h1, h2, h3, h4, h5, h6, blockquote {
    margin: 0;
}

/* to fix a chrome bug */
.scroll-parallax {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
}

img {
    max-width: 100%;
}

.body {
    overflow-x: hidden;
}