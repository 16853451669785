footer {
    height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: $size-footer-width-desktop;
        margin: 0 auto;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-around;
        @extend .text-color-grey;
        @extend .font-footer;
        
        @media screen and (max-width: $size-xs-max) {
            width: $size-footer-width-mobile;
            flex-direction: column;
        }
    }
    @media screen and (max-width: $size-sm-max) {
        height: 150px;


    }
}
