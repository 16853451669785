html {
    font-size: $font-size-base; // used for rem units
}
body {
    @extend .text-color;
    @extend .font-1;
    line-height: 1;
    a {
        @extend .text-color-primary;
        @extend .font-1;
        text-decoration: none !important;
        &:hover, &:focus {
            text-decoration: none !important;
             @extend .text-color-contrast;
        }
    }
    a[href^=tel], a[href^=mailto] {
        &:hover {

        }
    }
    h1.referencement {
        display: none !important;
    }
    .appear {
        opacity: 0;
        @include transition(opacity 0.75s ease-in);
    }
    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: 768px) {
            background-attachment: scroll;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    @extend .font-2;
}
ul {
  padding-left: 25px;
  text-align: left;
}
p {
    @extend .font-main;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
    padding: 20px 0;
}
li {
    @extend .font-main;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 700;
}
h4 {
  font-weight: 700;
  color: $color-blue-text;
  text-transform: uppercase;
  font-size: 24px;
  padding: 20px 0;
  @media screen and (max-width: 767px){
    font-size: 20px;
  }
}
.text p, .text div {
    @extend .text-color;
    font-size: 20px;
    line-height: 1.4;
}
.text p, .text div {
    @extend .font-1;
}
.text strong {
    color: $color-primary;
    font-weight: 900;
}
.text em {
    color: $color-2;
    font-style: normal;
    font-weight: 600;
}
